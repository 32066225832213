/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Attendance',
  data: function data() {
    return {
      pullDownRefresh: {
        threshold: 60,
        // 下拉多少开始刷新
        stop: 40 // 下拉回弹到多少停住

      },
      pullUpLoad: {
        threshold: 25
      },
      isPullDown: true,
      // 正在下拉刷新
      isPullUp: false,
      // 正在上拉加载
      emptyShow: false,
      recordsList: [],
      thisListidx: -1,
      endDate: '',
      currDate: '',
      currDates: '',
      weeksidx: 0
    };
  },
  watch: {},
  mounted: function mounted() {
    this.currDate = this.$route.query.currDate;
    this.currDates = this.$route.query.currDates;
    this.endDate = this.$route.query.endDate;
    this.weeksidx = this.$route.query.weeksidx;
    this.setWeChatTitle(this.$route.query.text);
    this.getSelectUserList();
  },
  methods: {
    onthisListidx: function onthisListidx(item, i) {
      item.isshow = !item.isshow;

      if (item.list.length === 0) {// return   this.$utils.Tools.toastShow('')
      }

      if (this.thisListidx == i) {
        this.thisListidx = -1;
      } else {
        this.thisListidx = i;
      } // this.recordsList[i].isshow = !item.isshow

    },

    /**
     * 设置微信标题
     *
     *
     */
    setWeChatTitle: function setWeChatTitle(title) {
      document.title = title + '统计';
      var mobile = navigator.userAgent.toLowerCase();

      if (/iphone|ipad|ipod/.test(mobile)) {
        var iframe = document.createElement('iframe');
        iframe.style.display = 'none'; // 替换成站标favicon路径或者任意存在的较小的图片即可

        iframe.setAttribute('src', '/favicon.ico');

        var iframeCallback = function iframeCallback() {
          setTimeout(function () {
            iframe.removeEventListener('load', iframeCallback);
            document.body.removeChild(iframe);
          }, 0);
        };

        iframe.addEventListener('load', iframeCallback);
        document.body.appendChild(iframe);
      }
    },
    // 下拉刷新
    onPullingDown: function onPullingDown() {
      if (this.isPullDown) return;
      this.isPullDown = true; // 下拉刷新
    },
    onPullingUp: function onPullingUp() {
      if (this.isPullUp) return;
      this.isPullUp = true;
    },
    // 刷新 Scroll 状态
    updateScroll: function updateScroll() {
      this.isPullDown = false;
      this.isPullUp = false;
      this.$refs.scroll.forceUpdate();
    },
    getSelectUserList: function getSelectUserList() {
      var _this = this;

      this.$utils.Tools.loadShow('加载中');
      this.$api.Attence.getSelectUserList({
        flag: this.$route.query.idx,
        data: this.$utils.Store.getItem('userSerial'),
        depSerial: this.$route.query.list,
        end: this.weeksidx == 1 ? this.currDates + '-30' + ' 23:59:59' : this.endDate + ' 23:59:59',
        start: this.weeksidx == 1 ? this.currDates + '-01' + ' 00:00:00' : this.currDate + ' 00:00:00'
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        _this.$utils.Tools.loadHide();

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          data.map(function (item) {
            item.isshow = false;
          });

          var list = _this.groupBy(data, 'userDep');

          var arr = [];

          for (var key in list) {
            arr.push(list[key]);
          } //this.recordsList = [].concat.apply([], arr)


          _this.recordsList = arr;

          _this.$utils.Tools.loadHide();
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    groupBy: function groupBy(list, name) {
      return list.reduce(function (obj, item) {
        if (!obj[item[name]]) {
          obj[item[name]] = [];
          obj[item[name]].push(item);
        } else {
          obj[item[name]].push(item);
        }

        return obj;
      }, []);
    }
  }
};